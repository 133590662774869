<template>
  <div class="AgentSelect">
    <FormSelect
      v-bind="$attrs"
      v-model="model"
      label="Agent"
      field-key="id"
      :field-text="[ 'lastname', 'firstname' ]"
      :placeholder="placeholder"
      :options="agents"
      @change="onChange"
    />
  </div>
</template>

<script>
  import FormSelect from '@/components/base/FormSelect'

  import { mapState } from 'vuex'

  export default {
    components: {
      FormSelect
    },

    props: {
      modelValue: {
        type: String,
        default: null
      },

      placeholder: {
        type: String,
        default: '— Select an Agent —'
      }
    },

    emits: [
      'update:modelValue'
    ],

    data() {
      return {
        model: this.modelValue
      }
    },

    computed: {
      ...mapState('agents', [ 'agents' ])
    },

    methods: {
      onChange() {
        this.$emit('update:modelValue', this.model)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'
</style>s
