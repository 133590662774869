<template>
  <main class="ClientsView">
    <div class="container">
      <header class="mb-3 d-flex">
        <h1 class="m-0">Clients</h1>

        <div class="ms-auto ClientsView__filters">
          <input
            ref="filter"
            type="text"
            placeholder="Filter clients…"
            class="form-control form-control-sm w-50 mb-3"
            v-model="filter"
          >

          <button class=" btn-sm btn btn-outline-primary" @click="sendAllInvoices()" v-if="isAuthAdmin">
            Send invoices
          </button>

          <button class=" btn-sm btn btn-primary" @click="openForm()" v-if="isAuthAdmin">
            Add Client
          </button>
        </div>
      </header>

      <AppLoading v-if="loading" :spaced="false" />

      <AppEmptyList v-if="!loading && !clients.length" />

      <section class="ClientsView__list mb-5" v-if="!loading && clients.length">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="ClientsView__table table">
                <colgroup>
                  <col style="width: 40px">
                  <col>
                  <col>
                  <col>
                  <col>
                  <col style="width: 150px">
                  <col style="width: 80px">
                </colgroup>
                <thead>
                  <tr>
                    <th />
                    <th>Last name</th>
                    <th>First name</th>
                    <th>Company</th>
                    <th>Email</th>
                    <th class="text-end">Balance</th>
                    <th />
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="client in filteredClients" :key="client.id" :class="{ 'active': isClientImpersonated(client), 'table-danger': isClientNotPayer(client) }">
                    <td>
                      <UserAvatar :user="client" />
                    </td>
                    <td>
                      {{ client.lastname }}
                    </td>
                    <td>
                      {{ client.firstname }}
                    </td>
                    <td>
                      {{ client.company_name || '-' }}
                    </td>
                    <td>
                      <a :href="'mailto:' + client.email">
                        {{ client.email }}
                      </a>
                    </td>
                    <th class="text-end">
                      ${{ formatDecimal(client.balance ) }}
                    </th>
                    <td class="text-end">
                      <button
                        title="Last invoice sent"
                        class="btn btn-link btn-sm"
                        v-if="isAuthAdmin && !client.last_invoice_sent"
                        disabled
                      >
                        <i class="bi bi-hourglass" />
                      </button>

                      <button
                        title="Impersonate"
                        class="btn btn-link btn-sm"
                        @click="openConfirmImpersonate(client)"
                        :disabled="isClientImpersonated(client)"
                      >
                        <i class="bi bi-person-check" />
                      </button>

                      <DropdownMenu align="end" v-if="isAuthAdmin">
                        <i class="bi bi-gear" />

                        <template #menu>
                          <DropdownItem @click="openForm(client)">
                            Edit
                          </DropdownItem>
                          <DropdownItem @click="regeneratePassword(client)">
                            Re-generate password
                          </DropdownItem>
                          <DropdownItem @click="regenerateInvoice(client)">
                            Re-generate Invoice
                          </DropdownItem>
                          <DropdownItem @click="sendInvoice(client)" :class="{ disabled: client.last_invoice_sent }">
                            Send invoice
                          </DropdownItem>
                          <DropdownItem @click="tagAsPayer(client)" :class="{ disabled: client.payer }">
                            Tag as payer
                          </DropdownItem>
                        </template>
                      </DropdownMenu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
  import AppLoading from '@/components/AppLoading'
  import AppEmptyList from '@/components/AppEmptyList'
  import UserAvatar from '@/components/UserAvatar'
  import ModalClientForm from '@/components/ModalClientForm'
  import ModalConfirm from '@/components/ModalConfirm'
  import DropdownMenu from '@/components/base/DropdownMenu'
  import DropdownItem from '@/components/base/DropdownItem'

  import clientService from '@/services/clients.service'
  import invoiceService from '@/services/invoices.service'

  import useModal from '@/plugins/modal/useModal'
  import useToast from '@/plugins/toast/useToast'
  import { formatRelativeDate } from '@/utils/date'
  import { formatDecimal } from '@/utils/number'
  import { filterCollection } from '@/utils/filters'
  import { mapState, mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      AppLoading,
      AppEmptyList,
      UserAvatar,
      DropdownMenu,
      DropdownItem
    },

    setup() {
      return {
        modal$: useModal(),
        toast$: useToast()
      }
    },

    data() {
      return {
        filter: '',
        loading: true,
        clients: null
      }
    },

    computed: {
      ...mapState('auth', [
        'authenticated',
        'impersonated'
      ]),

      ...mapGetters('auth', [
        'isAuthAdmin',
        'isAuthAgent',
        'isAuthImpersonate'
      ]),

      filteredClients() {
        return filterCollection(this.clients, this.filter, [ 'firstname', 'lastname', 'company_name', 'account_number', 'email' ])
      }
    },

    mounted() {
      this.load()
    },

    methods: {
      ...mapActions('auth', [
        'setImpersonated'
      ]),

      formatRelativeDate,
      formatDecimal,

      async load() {
        this.loading = true
        this.clients = await clientService.fetch()
        this.loading = false
      },

      isClientNotPayer(client) {
        return this.isAuthAdmin && !client.payer
      },

      isClientImpersonated(client) {
        return this.impersonated && this.impersonated.id === client.id
      },

      regeneratePassword(client) {
        const title = 'Regenerate password'

        this.modal$
          .open(ModalConfirm, {
            props: {
              title: title,
              text: 'Please confirm re-generating a new password for  <b>' + client.firstname + ' ' + client.lastname + '</b>'
            }
          })
          .onClose(async (confirm) => {
            if (confirm) {
              try {
                await clientService.regeneratePassword(client.id)
                this.toast$.success(title, 'A new password has been generated.')
              } catch (e) {
                console.error(e)
                this.toast$.danger(title, 'Something went wrong. Try later.')
              }
            }
          })
      },

      regenerateInvoice(client) {
        const title = 'Regenerate last invoice'

        this.modal$
          .open(ModalConfirm, {
            props: {
              title: title,
              text: 'Please confirm re-generating the last invoice for  <b>' + client.firstname + ' ' + client.lastname + '</b>'
            }
          })
          .onClose(async (confirm) => {
            if (confirm) {
              try {
                await clientService.regenerateLastInvoice(client.id)
                this.toast$.success(title, 'The last invoice has been re-regenerated.')
                client.payer = false
              } catch (e) {
                console.error(e)
                this.toast$.danger(title, 'Something went wrong. Try later.')
              }
            }
          })
      },

      async tagAsPayer(client) {
        const title = 'Tag as payer'

        try {
          await clientService.tagAsPayer(client.id)
          this.toast$.success(title, 'The user has been tagged as a payer.')
          client.payer = true
        } catch (e) {
          this.toast$.danger(title, 'Something went wrong. Try later.')
        }
      },

      async sendInvoice(client) {
        const title = 'Send pending invoice to client'

        this.modal$
          .open(ModalConfirm, {
            props: {
              title: title,
              text: 'Please confirm sending pending invoice to  <b>' + client.firstname + ' ' + client.lastname + '</b>'
            }
          })
          .onClose(async (confirm) => {
            if (confirm) {
              try {
                await clientService.sendInvoice(client.id)
                this.toast$.success(title, 'The invoice has been sent to the client.')
                client.last_invoice_sent = true
              } catch (e) {
                this.toast$.danger(title, 'Something went wrong. Try later.')
              }
            }
          })
      },

      async sendAllInvoices() {
        const title = 'Send all pending invoices'

        this.modal$
          .open(ModalConfirm, {
            props: {
              title: title,
              text: 'Please confirm sending pending invoices to clients'
            }
          })
          .onClose(async (confirm) => {
            if (confirm) {
              try {
                await invoiceService.sendAll()
                this.toast$.success(title, 'All invoices have been sent to clients.')
              } catch (e) {
                this.toast$.danger(title, 'Something went wrong. Try later.')
              }
            }
          })
      },

      openForm(client) {
        this.modal$
          .open(ModalClientForm, {
            closeOnBackdrop: false,
            scrollable: true,
            props: {
              client
            }
          })
          .onClose((response) => {
            if ([ 'DELETED', 'SAVED' ].includes(response)) {
              this.load()
            }
          })
      },

      openConfirmImpersonate(client) {
        this.modal$
          .open(ModalConfirm, {
            scrollable: true,
            props: {
              title: 'Impersonate client',
              text: 'Please confirm the impersonation of the client <b>' + client.firstname + ' ' + client.lastname + '</b>'
            }
          })
          .onClose((confirm) => {
            if (confirm) {
              this.setImpersonated(client)
              this.$router.push({ name: 'dashboard' })
            }
          })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .ClientsView__filters
    display: flex
    align-items: center
    justify-content: center
    gap: 1em

    input.form-control
      height: 100%
      margin: 0 !important
      width: 150px !important

  .ClientsView__table
    margin-bottom: 150px

    tr
      vertical-align: middle

      &.active
        th, td
          background: lighten($secondary, 42)
          font-weight: bold

      &.active.table-danger
        th, td
          background: lighten($danger, 42)

    th, td
      white-space: nowrap

    .dropdown
      display: inline-block

  .ClientsView__table__logo
    width: 32px
    height: 32px

  .bi-hourglass
    color: $warning
</style>
